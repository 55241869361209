console.log("gsap active");

//var $ = jQuery;

//var windowwidth = jQuery(window).width();
//var windowheight = jQuery(window).height();
//console.log(windowwidth);

// import { gsap, ScrollTrigger, ScrollToPlugin, CSSRulePlugin } from "gsap/all";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
// gsap.registerPlugin(CSSRulePlugin);
// gsap.registerPlugin(ScrollToPlugin);

//default setting per tutte le animazioni
// _______________________________________________
gsap.config({
  nullTargetWarn: false,
});
gsap.defaults({
  //ease: "power4.out",
  overwrite: "auto",
});
ScrollTrigger.defaults({
  toggleActions: "play complete play reverse",
});

// MENU E ANIMAZIONI HEADER
// _______________________________________________
let menuTl = gsap.timeline({ paused: true });
let menuTlBt = gsap.timeline({ paused: true });
// let logo = $(".logo-color");
let navbarMenu = $(".navbar-menu");
let navbarMenu_a = $(".navbar-menu .menu");
// let navbarMenu_contact = $(".navbar-menu .contact");
let showScrolling = $(".showscrolling");
// let whatsApp = $(".whatsapp");

// FADING IMAGES MENU
// _______________________________________________
const menuItems = document.querySelectorAll(".menu-item");
const images = gsap.utils.toArray(".image");
let currentIndex = 0;

menuItems.forEach((menuItem, index) => {
  menuItem.addEventListener("mouseenter", () => {
    const imageSrc = menuItem.getAttribute("data-image");
    const imageIndex = Array.from(images).findIndex((image) => image.getAttribute("src") === imageSrc);

    if (imageIndex !== -1 && imageIndex !== currentIndex) {
      const currentImage = images[currentIndex];
      const nextImage = images[imageIndex];

      gsap.timeline().set(currentImage, { opacity: 0 }).set(nextImage, { opacity: 1 }).set(menuItems, { color: "black" });
      //.set(menuItem, { color: "blue" });

      currentIndex = imageIndex;
    }
  });
});

// MENU
// _______________________________________________
gsap.set(navbarMenu, { autoAlpha: 1, display: "block", xPercent: 100 });
gsap.set(navbarMenu_a, { autoAlpha: 0, yPercent: -100, scale: 0 });

gsap.set(showScrolling, { autoAlpha: 0 });

menuTl
  // .to(whatsApp, {
  //   autoAlpha: 0,
  // })
  .to(navbarMenu, {
    autoAlpha: 1,
    display: "block",
    xPercent: 0,
  })
  .to(navbarMenu_a, {
    autoAlpha: 1,
    yPercent: 0,
    scale: 1,
    duration: 1,
    stagger: "0.1",
    ease: "expo.in",
  });

// .to(navbarMenu_contact, {
//   autoAlpha: 1,
//   yPercent: 0,
// });

// ANIMAZIONE MENU
menuTlBt
  .to(".span-home", {
    scale: 0,
    duration: 1,
  })
  .to(".navbar-burger", {
    backgroundColor: "transparent",
    boxShadow: "none",
  });

gsap.to(showScrolling, {
  scrollTrigger: {
    trigger: "body",
    start: "+=600px",
    end: "+=600px",
    scrub: true,
  },
  autoAlpha: 1,
});

/*funzione che lancia le animazioni del menu*/
// _______________________________________________
function menuPwFullscreen() {
  let menuopen = false;

  $(".navbar-burger").on("click", function (e) {
    e.preventDefault();

    $("body").addClass("showMenu");

    if (menuopen != true) {
      menuopen = true;

      // gsap.to(logo, {
      //   background: "#f9f10e",
      //   duration: 1,
      // });

      menuTl.timeScale(2).play();
      menuTlBt.timeScale(4).play();
    } else {
      $("body").removeClass("showMenu");

      // gsap.to(logo, {
      //   background: "white",
      //   duration: 1,
      // });

      menuTl.timeScale(5).reverse();
      menuTlBt.timeScale(5).reverse();
      menuopen = false;
    }
    //$(".navbar-menu").toggleClass("hidden");
  });
}
document.addEventListener("DOMContentLoaded", () => {
  console.log("dom loaded!");
  menuPwFullscreen();
});

// AMIMAZIONE PULSANTE MENU
// _______________________________________________
let menuBt = $(".navbar-burger .menu-bt");
let menuBt_circle = gsap.utils.toArray(".menu-bt .circle, .case-box__cta .circle, .cta .circle");
menuBt_circle.forEach((circle) => {
  gsap.to(circle, {
    scale: 1.5,
    stagger: 2,
    duration: 1,
    repeat: -1,
    yoyo: true,
  });
});

// EFFETTI BOX WORKS
// _______________________________________________
let caseBox = gsap.utils.toArray(".case-box");
caseBox.forEach((container) => {
  let over_color = container.querySelector(".over-color");
  let over_white = container.querySelector(".over-white");
  let caseBoxTitle = container.querySelector(".case-box__title");

  //creo 3 animazioni per ciascun elemento
  let tl_revealBox_color = gsap.timeline({
    scrollTrigger: {
      trigger: container,
      start: "top 80%",
      markers: false,
    },
  });
  let tl_revealBox_white = gsap.timeline({
    scrollTrigger: {
      trigger: container,
      start: "top 80%",
    },
  });
  let tl_revealBox_title = gsap.timeline({
    scrollTrigger: {
      trigger: container,
      start: "top 80%",
    },
  });

  gsap.set(caseBoxTitle, { yPercent: 100, autoAlpha: 0 });
  gsap.set(over_color, { xPercent: -100, overwrite: true });

  tl_revealBox_color.to(over_color, { xPercent: 0, ease: "power4.out", duration: 0.2 }).to(over_color, { xPercent: 100, ease: "power4.in", duration: 0.2 }).to(over_color, { autoAlpha: 0 });
  tl_revealBox_white.to(over_white, { xPercent: 100, delay: 0.1 });
  tl_revealBox_title.to(caseBoxTitle, { yPercent: 0, autoAlpha: 1 });
});

// EFFETTI IMG BOX
// _______________________________________________
let imgBox = gsap.utils.toArray(".img-box, .bg-box");
imgBox.forEach((container) => {
  let over_color = container.querySelector(".over-color");
  let over_white = container.querySelector(".over-white");
  //let caseBoxTitle = container.querySelector(".case-box__title");

  //creo 3 animazioni per ciascun elemento
  let tl_revealBox_color = gsap.timeline({
    scrollTrigger: {
      trigger: container,
      start: "top 70%",
      markers: false,
    },
  });
  let tl_revealBox_white = gsap.timeline({
    scrollTrigger: {
      trigger: container,
      start: "top 70%",
    },
  });
  /*
  let tl_revealBox_title = gsap.timeline({
    scrollTrigger: {
      trigger: container,
      start: "top 80%",
    },
  });
  */

  //gsap.set(caseBoxTitle, { yPercent: 100, autoAlpha: 0 });
  gsap.set(over_color, { xPercent: -100, overwrite: true });

  tl_revealBox_color.to(over_color, { xPercent: 0, ease: "power4.out", duration: 0.2 }).to(over_color, { xPercent: 100, ease: "power4.in", duration: 0.2 });
  tl_revealBox_white.to(over_white, { xPercent: 100, delay: 0.1 });
  //tl_revealBox_title.to(caseBoxTitle, { yPercent: 0, autoAlpha: 1 });
});

//EFFETTI VARI
// _______________________________________________
//animazione background immagini
let animBg = gsap.utils.toArray(".bganim");
animBg.forEach((anim) => {
  gsap.set(anim, {
    autoAlpha: 0,
    width: "0%",
  });
  gsap.to(anim, {
    scrollTrigger: {
      trigger: anim,
      start: "top 65%",
      markers: false,
    },
    autoAlpha: 1,
    width: "100%",
  });
});

//animazione colore class gsap-highlight
let highWrapper = gsap.utils.toArray(".text-orange-500");
highWrapper.forEach((anim) => {
  gsap.set(anim, {
    //text-slate-800
    color: "#1e293b",
  });
  gsap.to(anim, {
    duration: 2,
    //text-orange-500
    color: "#f97316",
    scrollTrigger: {
      trigger: anim,
      start: "top 75%",
      markers: false,
    },
  });
});

//animazione background da destra a sinistra
let animBgDx = gsap.utils.toArray(".bganim-dx");
animBgDx.forEach((anim) => {
  gsap.set(anim, {
    autoAlpha: 0,
    right: 0,
    width: "0%",
  });
  gsap.to(anim, {
    scrollTrigger: {
      trigger: anim,
      start: "top 75%",
      markers: false,
    },
    autoAlpha: 1,
    width: "100%",
    left: 0,
  });
});

//animazione background immagini
let bgImg = gsap.utils.toArray(".bgimg");
bgImg.forEach((anim) => {
  gsap.set(anim, {
    autoAlpha: 0,
  });
  gsap.to(anim, {
    scrollTrigger: {
      trigger: anim,
      start: "bottom bottom",
      markers: false,
    },
    autoAlpha: 0.1,
    duration: 0.5,
  });
});

//animazione pulsante assistenza
// let assistenza = $(".assistenza");
// gsap.set(assistenza, {
//   autoAlpha: 0,
// });
// gsap.to(assistenza, {
//   // scrollTrigger: {
//   //   trigger: assistenza,
//   //   start: "top 75%",
//   //   markers: true,
//   // },
//   autoAlpha: 1,
//   delay: 5,
//   duration: 3,
// });

//immagini servizi che si ingrandiscono (home)
let pngServizi = gsap.utils.toArray(".pngservizi");
pngServizi.forEach((anim) => {
  gsap.set(anim, {
    autoAlpha: 0,
    scale: 0,
  });
  gsap.to(anim, {
    scrollTrigger: {
      trigger: anim,
      start: "top 75%",
      markers: false,
    },
    autoAlpha: 1,
    scale: 1,
  });
});

//scritta blog che sale (home)

// let scaleUp = gsap.utils.toArray(".scaleup");
// scaleUp.forEach((anim) => {
//   gsap.set(anim, {
//     autoAlpha: 0,
//     scale: 0,
//     yPercent: 100,
//   });
//   gsap.to(anim, {
//     scrollTrigger: {
//       trigger: anim,
//       start: "top bottom",
//       markers: false,
//     },

//     autoAlpha: 0.5,
//     scale: 1,
//     yPercent: 0,
//   });
// });

let fromBot = gsap.utils.toArray(".gsap-frombot");
fromBot.forEach((anim) => {
  gsap.set(anim, {
    autoAlpha: 0,
    yPercent: 50,
  });
  gsap.to(anim, {
    scrollTrigger: {
      trigger: anim,
      start: "top 75%",
      markers: false,
    },

    autoAlpha: 1,
    yPercent: 0,
  });
});

//immagine flottante (wordpress)
let floatingImg = gsap.utils.toArray(".gsap-floating");
if (floatingImg) {
  gsap.to(floatingImg, {
    yPercent: 5,
    duration: 4,
    repeat: "-1",
    ease: "sine",
    yoyoEase: true,
  });
}

//logo che compare scorrendo (wordpress)

// let bgWp = $(".gsap-bgwp");

// if (bgWp) {
//   gsap.set(bgWp, { autoAlpha: 0 });
//   gsap.to(bgWp, {
//     scrollTrigger: {
//       trigger: "body",
//       start: "+=4000",
//       scrub: true,
//       markers: false,
//     },
//     autoAlpha: 1,
//   });
// }

//animazione background

let bgAnim = $(".gsap-bg-anim");
let tl_bgAnim = gsap.timeline({
  yoyo: true,
  repeat: -1,
});
if (bgAnim) {
  tl_bgAnim
    .to(bgAnim, {
      backgroundColor: "#fa0447",
    })
    .to(bgAnim, {
      backgroundColor: "#14b8a6",
    })
    .to(bgAnim, {
      backgroundColor: "#f9322c",
    });
}

// animazione logo pensareweb first section

// let firstSection = $(".first-section");

// if (firstSection) {
//   gsap.to(".gsap-logopw", {
//     autoAlpha: 1,
//     duration: 5,
//     delay: 1,
//   });
//   gsap.to(".gsap-logopw", {
//     scrollTrigger: {
//       trigger: firstSection,
//       start: "top top",
//       scrub: true,
//     },
//     xPercent: 19,
//   });
//   gsap.to(".gsap-logopw-bg", {
//     scrollTrigger: {
//       trigger: firstSection,
//       start: "top top",
//       scrub: true,
//     },
//     backgroundColor: "#fa0447",
//     opacity: 0.1,
//   });
// }

// ANIMAZIONI RESPONSIVE
// _______________________________________________
gsap.matchMedia({
  "(max-width: 767px)": () => {
    //animazione logo con maschera
    let wpAnim = gsap.utils.toArray(".gsap-wpanim");
    gsap.fromTo(
      wpAnim,
      {
        autoAlpha: 0,
        x: "40%",
      },
      {
        autoAlpha: 0.5,
        duration: 5,
        delay: 1,
        x: "40%",
      }
    );

    //animazione mask first section
    let fsmask = gsap.utils.toArray(".gsap-fsmask");
    gsap.set(fsmask, { autoAlpha: 0 });
    gsap.to(fsmask, { autoAlpha: 0.5, duration: 5, delay: 1 });

    //reset only animation
    return function () {
      //tl.kill();
      //listeners.forEach(removeListeners => removeListeners())
    };
  },
  "(min-width: 768px)": () => {
    //animazione logo con maschera
    let wpAnim = gsap.utils.toArray(".gsap-wpanim");
    gsap.fromTo(
      wpAnim,
      {
        autoAlpha: 0,
        x: "40%",
      },
      {
        autoAlpha: 1,
        duration: 5,
        delay: 1,
        x: "40%",
      }
    );

    //animazione mask first section
    let fsmask = gsap.utils.toArray(".gsap-fsmask");
    gsap.set(fsmask, { autoAlpha: 0 });
    gsap.to(fsmask, { autoAlpha: 1, duration: 5, delay: 1 });

    //reset only animation
    return function () {
      //tl.kill();
      //listeners.forEach(removeListeners => removeListeners())
    };
  },
});

//PARALLAX (logo nodejs)
// _______________________________________________
// $("section").each(function () {
//   gsap.matchMedia({
//     "(min-width: 768px)": () => {
//       var tl = gsap.timeline({
//         scrollTrigger: {
//           trigger: this,
//           start: "-20% 80%",
//           end: "+=150%",
//           //end: "top 80%",
//           markers: false,
//           scrub: 1,
//         },
//       });
//       var svg = $(this).find(".child");
//       var wrapperHeight = $(this).css("height");

//       if (svg) {
//         gsap.set(svg, {
//           yPercent: -75,
//           autoAlpha: 0,
//           duration: 0.2,
//         });
//         tl.to(svg, { autoAlpha: 1 })
//           .to(svg, {
//             y: wrapperHeight,
//             ease: "Linear.easeNone",
//             duration: 1,
//           })
//           .to(svg, {
//             autoAlpha: 0,
//             duration: 0.2,
//           });

//         return () => {
//           tl.kill();
//         };
//       }
//     },
//   });
// });

// SKEW EFFECT
// _______________________________________________
let proxy = { skew: 0 },
  skewSetter = gsap.quickSetter(".skewElem", "skewY", "deg"), // fast
  clamp = gsap.utils.clamp(-10, 10);
// don't let the skew go beyond 20 degrees.

ScrollTrigger.create({
  onUpdate: (self) => {
    let skew = clamp(self.getVelocity() / -300);
    // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
    if (Math.abs(skew) > Math.abs(proxy.skew)) {
      proxy.skew = skew;
      gsap.to(proxy, {
        skew: 0,
        duration: 0.5,
        ease: "power3",
        overwrite: true,
        onUpdate: () => skewSetter(proxy.skew),
      });
    }
  },
});
// make the right edge "stick" to the scroll bar. force3D: true improves performance
gsap.set(".skewElem", { transformOrigin: "right center", force3D: true });

// BACKGROUND FIRST SECTION
// _______________________________________________
// let bgfirstsection = gsap.utils.toArray(".bgfirstsection");
// bgfirstsection.forEach((section) => {
//   gsap.set(section, {
//     autoAlpha: 0,
//   });
//   gsap.to(section, {
//     autoAlpha: 0.4,
//     duration: 5,
//   });
// });

//EFFETTI LISTE CHECK
// _______________________________________________
// let check = gsap.utils.toArray(".check > li, .textfromleft");
// check.forEach((el) => {
//   gsap.set(el, {
//     autoAlpha: 0,
//     xPercent: -5,
//   });
//   gsap.to(el, {
//     scrollTrigger: {
//       trigger: el,
//       start: "top 90%",
//       markers: false,
//       stagger: 0.025,
//     },
//     autoAlpha: 1,
//     xPercent: 0,
//   });
// });

// var textupBefore = CSSRulePlugin.getRule(".textup:before");
// console.log(textupBefore);

// let textUp = gsap.utils.toArray(".textup");
// textUp.forEach((container) => {
//   const splitCharEffect = new SplitTextJS(container);
//   const textWords = splitCharEffect.words;
//   //const textChars = splitCharEffect.chars;

//   if (textWords) {
//     gsap.set(textWords, {
//       autoAlpha: 0,
//       yPercent: 50,
//     });
//   }
//   let animTextUp = gsap.to(textWords, {
//     scrollTrigger: {
//       trigger: container,
//       start: "top 75%",
//       markers: false,
//     },
//     autoAlpha: 1,
//     yPercent: 0,
//     stagger: 0.025,
//     ease: "back.out(10)",
//   });
//   // animTextUp.timeScale(1).play();

// });
